import { Box, Typography } from "@material-ui/core";
import { Equalizer, List, Storage, School } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import GuideCard from "./components/GuideCard";
import Home from "./Home";
import UserGuide from "./UserGuide";
import { WorkflowTypesGuide } from "./guides/WorkflowTypesGuide";
import { ErlWorkflowGuide } from "./guides/ErlWorkflowGuide";
import { ReplicationWorkflowGuide } from "./guides/ReplicationWorkflowGuide";
import RedacticsContext from "../../contexts/RedacticsContext";
import { LearningCenter } from "../../types/redactics";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const WizardCardBox = styled(Box)`
{
  width: inherit;
  border: solid #6F6969 1pt;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  transition-property: box-shadow;
  transition-duration: .1s;
  &:hover {
    cursor:pointer;
    box-shadow: 0em 0em .3em .1em gray;
  }
}
`;

const Drawer = styled.div`
  {
    width: 300px;
    margin-right: -300px;
    flex-shrink: 0;
    background: #E0E0E0;
    border-left: solid lightgray 1pt;
    transition-property: margin-right;
    transition-duration: .5s;
    overflow: auto;
    height: 100vh;
    z-index: 999999;
  }
`;

interface IProps {
  toggleHelpCenter: (newPage: string) => void;
  helpCenterOpen: boolean;
}

/**
 * Main controller for the help center. Includes page router, methods to get and put to API, holds state variables for guide completion progress, the wizard card, and guide cards.
 * @param props 
 * @returns 
 */
export default function HelpCenter(props:IProps) {
  const context = useContext(RedacticsContext);
  const [page, setPage] = useState(sessionStorage.getItem('helpCenterPage') ?? 'home');
  const [guideCompletion, setGuideCompletion] = useState<LearningCenter>({
    // use negative indexes so a step won't be opened by default
    erl:-1,
    replication:-1,
    workflowTypes:-1,
  })

  // make request to API on every page change to reload values
  useEffect(() => {

    /**
    * Makes get request to API, then saves state.
    */
    async function refreshLearningCenter() {
    const response = await fetch(`${context.apiUrl}/helpcenter`, {
      credentials: 'include',
    });
    const data: LearningCenter = await response.json();
    setGuideCompletion(data);
  }

    refreshLearningCenter()
  }, [page, context.apiUrl]);

  /**
   * Component for the main "Start Wizard" card/button
   * @returns 
   */
  function WizardCard() {

    /**
     * Shit algorithm for determining which guide the wizard card should link to
     * @returns nav link
     */
    function handleRouting() {
      let route: string = '';
      // If guide hasn't been started, or hasn't been finished
      if (!guideCompletion.workflowTypes || guideCompletion.workflowTypes < WorkflowTypesGuide.length -1) {
        route = 'workflowTypes';
      }
      // If neither guide has been started, direct to ERL
      else if (!guideCompletion.erl && !guideCompletion.replication) {
        route = 'erl';
      }
      // If ERL has been started but not finished
      else if (guideCompletion.erl && guideCompletion.erl < ErlWorkflowGuide.length -1) {
        route = 'erl';
      }
      // IF replication has been started but not finished.
      else if (guideCompletion.replication && guideCompletion.replication < ReplicationWorkflowGuide.length -1) {
        route = 'replication';
      }
      // ERL is complete but not replication
      else if (guideCompletion.erl >= ErlWorkflowGuide.length - 1 && guideCompletion.replication < ReplicationWorkflowGuide.length - 1) {
        route = 'replication';
      }
      // Replication complete but not ERL
      else if (guideCompletion.replication >= ReplicationWorkflowGuide.length - 1 && guideCompletion.erl < ErlWorkflowGuide.length - 1) {
        route = 'erl';
      } 
      // If both ERL and replication are completed
      else if (guideCompletion.replication >= ReplicationWorkflowGuide.length - 1 && guideCompletion.erl >= ErlWorkflowGuide.length -1) {
        route = 'workflowTypes';
      }
      return route;
    }
  
    /**
     * Determines the text to display on the wizard card. If no progress, displays 'Begin Wizard". If progress, displays progress bar and percentage.
     * @returns
     */
    function displayBody() {
      // User hasn't started any guide
      if (Object.values(guideCompletion).every(val => !val)) {
        return "Begin Wizard";
      }
      const arr = [WorkflowTypesGuide.length, ErlWorkflowGuide.length, ReplicationWorkflowGuide.length]
      const numSteps = arr.reduce((accumulator, currentValue) => accumulator + currentValue) - arr.length;
      const stepsCompleted = Object.values(guideCompletion).reduce((accumulator, currentValue) => accumulator + currentValue);
      const percentage = stepsCompleted/numSteps >= 0 ? Math.ceil(stepsCompleted/numSteps * 100) : 0;
      return (
        <>
          <Box
            style={{flexGrow: '1', display: 'flex', justifyContent: 'center',alignItems: 'center'}}
          >Continue Wizard</Box>
          <Box style={{display: 'flex', alignItems: 'center', margin: '0 0 2px 5px'}}>
            <Box style={{width: '30px', marginRight: '5px'}}>
              <CircularProgressbar
                value={percentage}
                styles={buildStyles({
                  pathColor: 'secondary',
                })}
              />
            </Box>
            <Typography style={{color: 'rgba(0, 0, 0, 0.5)'}}>{percentage}% complete</Typography>
          </Box>
        </>
      );
    }

    // Don't show wizard card when erl and replication have been completed
    if (guideCompletion.erl >= ErlWorkflowGuide.length -1 && guideCompletion.replication >= ReplicationWorkflowGuide.length -1) {
      return <></>
    }

    return (
      <WizardCardBox
        onClick={() => handleSetPage(handleRouting())}
        mb={4}
      >
        <Box style={{width:'42%', height:'inherit', background: '#1B2430', borderRight: 'solid #6F6969 1pt', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><School style={{color: '#FFFFFF', fontSize: '70px'}}/></Box>
        <Box style={{flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center', fontSize: '18px', flexDirection: 'column' }}>
          {displayBody()}
        </Box>
      </WizardCardBox>
    )
  }

  // Define the guide cards with their props, then pass them down to components as props
  const GuideCards: {name: string, card: JSX.Element}[] = [
    {
      name: 'workflowTypes',
      card: 
        <GuideCard
          cardTitle="Learn about workflows"
          cardIcon={
            <List
              style={{fontSize: '70px', color:'white'}} />
            }
          completed={guideCompletion.workflowTypes}
          guideSteps={WorkflowTypesGuide.length}
          onClick={() => handleSetPage('workflowTypes')}
          key={1}
        />,
    },
    {
      name: 'erlWorkflow',
      card: 
        <GuideCard
          cardTitle="ERL workflow"
          cardIcon={
            <Equalizer
              style={{fontSize: '70px', color:'white'}} />
            }
          completed={guideCompletion.erl}
          guideSteps={ErlWorkflowGuide.length}
          onClick={() => handleSetPage('erl')}
          key={2}
        />,
    },
    {
      name: 'replicationWorkflow',
      card: 
        <GuideCard
          cardTitle="Replication workflow"
          cardIcon={
            <Storage
              style={{fontSize: '70px', color:'white'}} />
            }
          completed={guideCompletion.replication}
          guideSteps={ReplicationWorkflowGuide.length}
          onClick={() => handleSetPage('replication')}
          key={3}
        />,
    },
  ]

  /**
   * Save the users current help page, so that on new pages/refreshes, they will be brought to the same help page.
   * @param page Help center page to navigate to.
   */
  function handleSetPage (newPage: string) {
    sessionStorage.setItem('helpCenterPage', newPage);
    setPage(newPage);
  }

  /**
   * On each guide button click, this function is called to update the step that the user is on.
   * @param stepOnCurrently Current guide step a user is on
   * @param guide Name of guide, used as key
   */
  async function updateHelpCenter(stepOnCurrently: number, guide: keyof LearningCenter) {
    const copy = {...guideCompletion};
    copy[guide] = stepOnCurrently;
    setGuideCompletion(copy);
    await fetch(`${context.apiUrl}/helpcenter`, {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      method: "PUT",
      body: JSON.stringify(copy),
    });
  }

  /**
   * Handle learning center page routing
   * @param newPage 
   * @returns 
   */
  function helpCenterRouter(newPage: string) {
    switch (newPage) {
      case 'home':
        return (
          <Home
            toggleHelpCenter={() => props.toggleHelpCenter('helpCenter')}
            handleSetPage={handleSetPage}
            guideCards={GuideCards}
            wizardCard={WizardCard}
            key={newPage}
          />
        )
      case 'workflowTypes':
        return (
          <UserGuide
            handleSetPage={handleSetPage}
            toggleHelpCenter={() => props.toggleHelpCenter('helpCenter')}
            nextGuideCard={[GuideCards[1].card, GuideCards[2].card]}
            guide={WorkflowTypesGuide}
            guideTitle="Workflow Types"
            estimatedTime="3 minutes"
            key={newPage}
            stepOnCurrently={guideCompletion.workflowTypes}
            name={newPage}
            updateHelpCenter={updateHelpCenter}
          />
        )
      case 'erl':
        return (
          <UserGuide
            handleSetPage={handleSetPage}
            toggleHelpCenter={() => props.toggleHelpCenter('helpCenter')}
            guide={ErlWorkflowGuide}
            guideTitle="ERL Workflow"
            estimatedTime="10 minutes"
            key={newPage}
            nextGuideCard={[GuideCards[2].card]}
            stepOnCurrently={guideCompletion.erl}
            name={newPage}
            updateHelpCenter={updateHelpCenter}
          />
        )
      case 'replication':
        return (
          <UserGuide
            handleSetPage={handleSetPage}
            toggleHelpCenter={() => props.toggleHelpCenter('helpCenter')}
            guide={ReplicationWorkflowGuide}
            guideTitle="Replication Workflow"
            estimatedTime="10 minutes"
            key={newPage}
            nextGuideCard={[GuideCards[1].card]}
            stepOnCurrently={guideCompletion.replication}
            name={newPage}
            updateHelpCenter={updateHelpCenter}
          />
        )
    }
  }

  return (
    <Drawer style={props.helpCenterOpen ? {marginRight: '0px'} : {} }>
      <Box style={{margin: '0 15px'}}>
        {helpCenterRouter(page)}
      </Box>
    </Drawer>
  )
}