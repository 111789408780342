import React from 'react';

import { ContextObj } from '../types/redactics';

const contextObj:ContextObj = {
  apiUrl: '',
  isAuthenticated: false,
};
const RedacticsContext = React.createContext(contextObj);
export default RedacticsContext;
