import React from 'react';
import { Redirect } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import firebaseConfig from '../firebaseConfig';
import RedacticsContext from '../contexts/RedacticsContext';
import { AuthInfo, ContextObj } from '../types/redactics';

const apiUrl = process.env.REACT_APP_API_URL || '';

interface IProps {
  component: any;
  layout: any;
  computedMatch: any;
}

interface IState {
  isAuthenticated: boolean;
  authChecked: boolean;
  apiUrl: string;
  cookieSet: boolean;
  email?: string;
  dockerRegistryUrl?: string;
  chartMuseumUrl?: string;
  companyId?: string;
  apiKey?: string;
  ackHelmReminder?: boolean;
  ackErrorNotification?: boolean;
  cliUrl?: string;
  cliVersion?: string;
}

class ProtectedRoute extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isAuthenticated: false,
      authChecked: false,
      apiUrl,
      cookieSet: false,
    };

    initializeApp(firebaseConfig);
  }

  async componentDidMount() {
    try {
      const response = await fetch(`${apiUrl}/users`, {
        credentials: 'include',
      });
      if (!response.ok) {
        this.setState({
          isAuthenticated: false,
          authChecked: true,
          apiUrl,
          cookieSet: response.status !== 401,
        });
        return false;
      }
      const authData:AuthInfo = await response.json();
      const firebaseToken = authData.firebaseToken || '';
      const auth = getAuth();
      await signInWithCustomToken(auth, firebaseToken);
      this.setState({
        email: authData.email,
        isAuthenticated: true,
        authChecked: true,
        apiUrl,
        dockerRegistryUrl: authData.dockerRegistryUrl,
        chartMuseumUrl: authData.chartMuseumUrl,
        companyId: authData.companyId,
        apiKey: authData.apiKey,
        ackHelmReminder: authData.ackHelmReminder,
        ackErrorNotification: authData.ackErrorNotification,
        cliUrl: authData.cliUrl,
        cliVersion: authData.cliVersion,
      });
    } catch (err) {
      // console.log('Firebase error', err);
    }
    return true;
  }

  render() {
    const Component = this.props.component;
    const Layout = this.props.layout;

    if (!this.state.authChecked) {
      return null;
    }
    if (this.state.isAuthenticated) {
      const contextObj:ContextObj = this.state;
      return (
        <RedacticsContext.Provider value={contextObj}>
          <Layout>
            <Component params={this.props.computedMatch.params} />
          </Layout>
        </RedacticsContext.Provider>
      );
    }

    const loginPath = this.state.cookieSet ? '/login/invalidtoken' : '/login';
    return (
        <Redirect to={{ pathname: loginPath }} />
    );
  }
}

export default ProtectedRoute;
