import React, { useEffect, useState} from "react";
import BreadCrumbs from "./components/BreadCrumbs";
import HelpCenterTitle from "./components/HelpCenterTitle";
import DropDownStep from "./components/DropDownStep";
import {  Box } from "@material-ui/core";
import { LearningCenter } from "../../types/redactics";

interface IProps {
  handleSetPage: (page: string) => void;
  toggleHelpCenter: () => void;
  guide: {title: string, children: JSX.Element}[];
  nextGuideCard?: JSX.Element[];
  guideTitle: string;
  estimatedTime: string;
  name: string;
  stepOnCurrently: number;
  updateHelpCenter: (stepOnCurrently: number, guide: keyof LearningCenter)=> Promise<void>;
}

/**
 * Displays the steps of a given guide. Guide must be of format {title: value, children: value} (./guides for examples). Component keeps track of expanded and completed state variables.
 * @param props 
 * @returns 
 */
export default function UserGuide(props: IProps) {

  // Determines which steps have been completed
  const [completed, setCompleted] = useState<number>(props.stepOnCurrently);

  // Determines the expanded behavior of the guide
  const [expanded, setExpanded] = useState(() => {
    // First check if step is in local storage
    if (sessionStorage.getItem(props.name)) {
      return Number(sessionStorage.getItem(props.name));
    }
    else if (props.stepOnCurrently <= 0) {
      return 0;
    }
    else {
      return props.stepOnCurrently
    }
  })

  // Reloads state variables on step completion
  useEffect(() => {
    setCompleted(() => {
      if (props.stepOnCurrently == null) {
        return 0;
      }
      else return props.stepOnCurrently;
    });
  }, [props.stepOnCurrently]);
  
  /**
   * Only allows one step to be expanded at a time. Will close all other steps. Sets the local storage to remember the expanded position (crucial for page reloads)
   * @param index Index of step to modify
   */
  function handleExpansion (index: number) {

    // If the same index is passed as is the same index that is open, close specified index.
    if (expanded === index) {
      sessionStorage.setItem(props.name, (-1).toString());
      setExpanded(-1);
    }
    else {
      sessionStorage.setItem(props.name, (index).toString());
      setExpanded(index);
    }
  }

  /**
   * Go back a single guide step
   * @param index 
   */
  function backButton(index:number) {
    handleExpansion(index - 1);
  }

  /**
   * Go forward a guide step, makes call to API if the step is newly completed (doesn't make call if the user is backtracking through completed steps)
   * @param index 
   */
  function nextButton(index:number) {
    handleExpansion(index + 1);
    if (completed < index + 1) {
      setCompleted(index + 1);
      props.updateHelpCenter(index + 1, props.name as any)
    }
  }

  return (
    <>
      <BreadCrumbs
        toggleHelpCenter={props.toggleHelpCenter}
        handleSetPage={() => props.handleSetPage('home')}
      />
      <HelpCenterTitle
        title={props.guideTitle}
        estimatedTime={props.estimatedTime}
      />
      {props.guide.map((step, i) => {
        // If last step, then use "whatsNext" flag to display cards instead of buttons
        if ((props.guide.length - 1) === i) {
          return (
            <DropDownStep
              title={step.title}
              expanded={expanded === i}
              handleExpansion={() => handleExpansion(i)}
              whatsNext
            >
              {step.children}
              {props.nextGuideCard?.map(card =>
                <Box mt={4}>{card}</Box>
              )}
              
            </DropDownStep>
          )
        }
        // Render steps with "Back" and "Next" icons
        else { 
          return (
            <DropDownStep
              title={step.title}
              completed={completed > i}
              expanded={expanded === i}
              handleExpansion={() => handleExpansion(i)}
              backButton={() => backButton(i)}
              nextButton={() => nextButton(i)}
              disableFirstBackButton={i === 0 ? true: false}
            >
              {step.children}
            </DropDownStep>
          )
        }
      })}
    </>
  )
}