import { IconButton, Box } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import DocumentationCards from "./components/DocumentationCards";

interface IProps {
  toggleHelpCenter: () => void;
  handleSetPage: (page: string) => void;
  guideCards: {name: string, card: JSX.Element}[];
  wizardCard: () => JSX.Element;
}

/**
 * Default landing page for the help center section. Conditionally shows start wizard card, shows all guides, and has link to external documentation. 
 * @param props 
 * @returns 
 */
export default function Home (props: IProps) {
  return (
    <>
      <Box style={{display: 'flex', alignItems: 'center'}}>
        <h3 style={{fontWeight: '600', fontSize: '22px'}}>Learning Center</h3>
        <IconButton 
          onClick={() => props.toggleHelpCenter()} 
          style={{marginLeft: 'auto'}}
        >
          <CloseOutlined />
        </IconButton>
      </Box>
      
      {props.wizardCard()}
      
      <Box mb={4}>
        <h4 style={{fontSize: '20px', fontWeight: '600', margin: '0'}}>Guides</h4>
        <p style={{margin: '0', color: 'rgba(0, 0, 0, 0.5)'}}>Quick guides to get started</p>
      </Box>

      {props.guideCards.map(guideCard => {
        return guideCard.card
      })}

      <Box mt={6} mb={4}>
        <h4 style={{fontSize: '20px', fontWeight: '600', margin: '0'}}>Documentation</h4>
        <p style={{margin: '0', color: 'rgba(0, 0, 0, 0.5)'}}>External resources</p>
      </Box>

      <Box
        style={{display: 'grid', gap: '15px', gridTemplateColumns:'1fr 1fr'}}
      >
        <DocumentationCards
          title="Kafka Connect"
          icon={<img src={require('./icons/kafka-icon.svg')} height='50px' alt="Kafka connect logo"></img>}
          href="https://docs.confluent.io/platform/current/connect/index.html"
        />
        <DocumentationCards
          title="Google DLP"
          icon={<img src={require('./icons/google-icon.svg')} height='50px' alt="google dlp logo"></img>}
          href="https://cloud.google.com/security/products/dlp?hl=en"
        />
        <DocumentationCards
          title="Postgres"
          icon={<img src={require('./icons/postgres-icon.svg')} height='50px' alt="postgresql logo"></img>}
          href="https://www.postgresql.org/docs/"
        />
        <DocumentationCards
          title="Kubernetes"
          icon={<img src={require('./icons/kubernetes-icon.svg')} height='50px' alt="kubernetes logo"></img>}
          href="https://kubernetes.io/docs/home/"
        />
      </Box>

      <Box mt={6} mb={4}>
        <h4 style={{fontSize: '20px', fontWeight: '600', margin: '0'}}>Redactics Agent</h4>
        <a href="https://github.com/Redactics/redactics-osedition" target="_blank" rel="noopener noreferrer">
          <p style={{margin: '0'}}>View redactics agent source code</p>
        </a>
      </Box>
    </>
  )
}
