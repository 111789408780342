import { Box, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { AgentFirebaseRecord } from "../../../types/redactics";

const StacktraceViewerScreen = styled.div`
  {
    visibility: hidden;
    top: 0;
    right: 300px;
    position: absolute;
    width: calc(100vw - 260px - 300px);
    background-color: rgba(128,128,128,0);
    height: 100vh;
    z-index: 1101;
    transition: visibility 0.2s, background-color 0.2s linear;
    display: flex;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  {
    border-radius: 10px;
    border: solid black 1pt;
    overflow: auto;
    height: 96vh;
    background-color: white;
    padding: 0;
    margin: 20px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s linear;
  }
`

const CloseButton = styled(IconButton)`
  {
    padding: 0px;
  }
`

interface IProps {
  firebaseObject: AgentFirebaseRecord;
  open: boolean;
  toggleStackTraceViewer: () => void;
}

export default function StacktraceViewer(props: IProps) {

  function newlineToBreak(input: string | undefined) {
    if (!input) {
      return '';
    }
    const newlineArray = input.split('\n');
    const mappedArray = newlineArray.map((line, index) => <p style={{ margin: '10px 0' }} key={index}>{line}</p>);
    return mappedArray;
  }

  return (
    <StacktraceViewerScreen style={props.open ? { visibility: 'visible', backgroundColor: 'rgba(128,128,128,0.9)' } : {}}>
      <ContentContainer
        style={props.open ? { visibility: 'visible', opacity: 1 } : {}}
      >
        <Box style={{ display: 'flex', justifyContent: 'end', position: 'sticky', top: '0', padding: '5px 10px 0 0' }}>
          <CloseButton
            aria-label="exit"
            onClick={() => props.toggleStackTraceViewer()}
          >
            <Close
              style={{ color: "black", fontSize: '25px' }}
            />
          </CloseButton>
        </Box>
        <Box style={{ padding: '0 10px' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" style={{ flexGrow: '1' }}>{props.firebaseObject?.exception}</Typography>
          </Box>
          <Box>
            <Typography style={{ margin: '5px 0' }}><b>Date: </b>{new Date(props.firebaseObject.timestamp).toUTCString()}</Typography>
            <Typography style={{ margin: '5px 0' }}><b>Workflow name: </b>{props.firebaseObject.workflowName}</Typography>
          </Box>
          <Box style={{ margin: '15px 0' }}>
            <Typography variant="h5">Stack Trace:</Typography>
          </Box>
          <div>{newlineToBreak(props.firebaseObject.stackTrace)}</div>
        </Box>
      </ContentContainer>
    </StacktraceViewerScreen>
  );
}