import { Box, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, makeStyles } from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";
import React from "react";
import { Flag } from "react-feather";

const useStyles = makeStyles ({
  ExpansionPanel: {
    background: 'inherit',
    boxShadow: 'none',
    position: 'static' as 'static',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  root: {
    transitionProperty: 'box-shadow',
    transitionDuration: '.1s',
    padding: '0',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0em 0em .3em .1em gray',
    },
    '&.Mui-expanded': {
      minHeight: '48px',
    },
  },
  content: {
    alignItems: 'center',
    margin: '0',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  details: {
    padding: '0',
    margin: '10px 0',
    display: 'block',
  },
});

interface IProps {
  completed?: boolean;
  title: string;
  children?: React.ReactNode;
  expanded: boolean;
  handleExpansion: () => void;
  classes?: any;
  backButton?: () => void;
  nextButton?: () => void;
  disableFirstBackButton?: boolean;
  whatsNext?: boolean;
};

/**
 * Creates the drop down expansion panels for each guide step
 * @param props 
 * @returns 
 */
export default function DropDownStep (props: IProps) {
  const classes = useStyles();
  
  return (
    <ExpansionPanel
      classes={{
        root: classes.ExpansionPanel
      }}
      expanded={props.expanded}
      onChange={props.handleExpansion}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.root,
          content: classes.content,
        }}
      >
        {/* Determines what type of step to display */}
        {(function() {
          // If this flag is set, it will render the flag icon
          if (props.whatsNext) {
            return <Flag style={{fontSize: '30px', marginLeft: '7.4px'}} />
          }
          // If step is completed, will render a check icon
          else if (props.completed) {
            return <CheckCircle style={{color: 'green', fontSize: '30px', marginLeft: '5px'}}/>
          }
          // If step not completed, renders an empty radio button
          else return <RadioButtonUnchecked style={{fontSize: '30px', marginLeft: '5px'}} />
        }
        )()}
        <Typography style={{marginLeft: '10px', fontWeight: '600', fontSize: '15px'}}>{props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.details,
        }}  
      >
        {props.children}
        <Box mt={4} display={props.whatsNext ? 'none' : 'block'}>
          <Button
            variant='outlined'
            style={{marginRight:' 10px'}}
            onClick={props.backButton}
            disabled={props.disableFirstBackButton}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.nextButton}
          >
            Next
          </Button>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  
  );    
};