import React from 'react';
import { connect } from 'react-redux';

import Helmet from 'react-helmet';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

import Routes from './routes/Routes';
import maTheme from './theme';

/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2044478a263b4dbda853cf73bfa9bb62@o1011893.ingest.sentry.io/6131937',
    environment: process.env.ENVIRONMENT || 'development',
  });
}

const tagManagerArgs = {
  gtmId: 'GTM-T27SHL5'
}

TagManager.initialize(tagManagerArgs)

function App({ theme }) {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Redactics"
        defaultTitle="Redactics"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Sentry.ErrorBoundary>
                <Routes />
              </Sentry.ErrorBoundary>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
