import { IconButton, Box } from "@material-ui/core";
import { ArrowBack, CloseOutlined } from "@material-ui/icons";
import React from "react";

interface IProps {
  toggleHelpCenter: () => void;
  handleSetPage: () => void;
}

/**
 * Creates the back button and the close button when viewing page guides.
 * @param props 
 * @returns 
 */
export default function BreadCrumbs (props:IProps) {
  return (
    <Box style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
      <IconButton onClick={props.handleSetPage}>
        <ArrowBack />
      </IconButton>
      <h3 style={{fontWeight: '600', fontSize: '18px', margin: '0 auto'}}>Learning Center</h3>
      <IconButton
        onClick={() => props.toggleHelpCenter()}
        style={{marginLeft: 'auto'}}
      >
        <CloseOutlined />
      </IconButton>
    </Box>
  )
}