import React from 'react';
import styled from 'styled-components';

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, query, orderByChild } from 'firebase/database';
import {
  Badge,
} from '@material-ui/core';

import {
  Bell,
} from 'react-feather';

import firebaseConfig from '../../../firebaseConfig';
import { AgentFirebaseRecord } from '../../../types/redactics';
import RedacticsContext from '../../../contexts/RedacticsContext';
import { ToggleButton } from '@material-ui/lab';

const app = initializeApp(firebaseConfig);
const fbDatabase = getDatabase(app);

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

interface IProps {
  toggleRightSidebar: (page: string) => void;
  rightSidebarPage: string;
  rightSidebarOpen: boolean;
  theme: any;
}

interface IState {
  anchorEl?: any;
  firebaseData: AgentFirebaseRecord[];
  unreadCount: number;
}

class Notifications extends React.Component<IProps, IState> {
  static contextType = RedacticsContext;

  constructor(props: IProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.processFirebaseData = this.processFirebaseData.bind(this);

    this.state = {
      anchorEl: null,
      firebaseData: [],
      unreadCount: 0,
    };
  }

  componentDidMount() {
    this.processFirebaseData();
  }

  handleClick(event:any) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  /* eslint-disable no-restricted-syntax */

  processFirebaseData() {
    let unreadCount = 0;
    const notifications = query(ref(fbDatabase, `notifications/${this.context.companyId}`), orderByChild('timestamp'));
    onValue(notifications, (snapshot) => {
      const data = snapshot.val();
      if (!data) { return; }
      unreadCount = 0;

      for (const v of Object.values(data).reverse()) {
        // cast Firebase data to AgentFirebaseRecord type
        const val: AgentFirebaseRecord = v as AgentFirebaseRecord;
        if (!val.ack && typeof val.ack !== 'undefined' && val.exception) {
          unreadCount += 1;
        }
      }

      this.setState({
        unreadCount,
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToggleButton
          color='inherit'
          onClick={() => this.props.toggleRightSidebar('notifications')}
          selected={this.props.rightSidebarPage === 'notifications' && this.props.rightSidebarOpen}
          value='check'
          style={{border: 'none', borderRadius: '1000000px', transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 10ms'}}
        >
          <Indicator badgeContent={this.state.unreadCount}>
            <Bell />
          </Indicator>
        </ToggleButton>
      </React.Fragment>
    );
  }
}

export default Notifications;
