import { Box } from "@material-ui/core";
import React from "react";


export const WorkflowTypesGuide: {title: string, children: JSX.Element}[] = [
  {
    title: 'Welcome',
    children:
      <Box>
        Welcome to Redactics! This wizard will help you set up your workflows, and give you a better understanding of the features of Redactics.
      </Box>
  },
  {
    title: 'What we do',
    children:
    <Box>
      Cras viverra sit amet mauris nec tincidunt. Aenean imperdiet eget velit ac sagittis. Suspendisse pulvinar tristique turpis et vestibulum. Phasellus finibus nisl id lorem dapibus, et malesuada purus egestas. Ut a fringilla ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </Box>
  },
  {
    title: 'What is a workflow?',
    children:
    <Box>
      Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus non ullamcorper lacus. Cras orci velit, finibus ac gravida ut, vulputate ut sem. Sed vel sollicitudin lectus, in condimentum nunc.
    </Box>
  },
  {
    title: 'ERL workflows',
    children: 
    <Box>ERL workflows uis porttitor viverra nibh, sed volutpat eros bibendum varius. Etiam imperdiet nunc et purus blandit, vitae lobortis lorem aliquam. Nulla in feugiat purus. Suspendisse eu est magna. Sed pretium, urna a lobortis congue, mauris lacus bibendum metus, id vulputate magna turpis et metus. Ut id nibh ut mauris varius tincidunt.</Box>
  },
  {
    title: 'Replication workflows',
    children: 
    <Box>
      Replication workflows sillam dui leo, pharetra vel nulla at, efficitur mattis ex. Curabitur dictum iaculis odio, sit amet faucibus enim eleifend ac. Suspendisse posuere felis vitae ante laoreet, id vehicula metus auctor. Ut vitae ex magna. 
    </Box>
  },
  {
    title: 'Select workflow type',
    children:
    <Box>
      To continue the wizard, choose either ERL or replication workflow.
    </Box>
  }
]