import React from "react";
import { Box as MUIBox } from "@material-ui/core";
import styled from "styled-components";

const Box = styled(MUIBox)`
  height: 100px;
  border: solid #6F6969 1pt;
  border-radius: 5px;
  transition-property: box-shadow;
  transition-duration: .1s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor:pointer;
    box-shadow: 0em 0em .3em .1em gray;
  }
}
`;

interface IProps {
  title: string;
  icon: JSX.Element;
  href: string;
}

/**
 * Component for showing external links on home page.
 * @param props 
 * @returns 
 */
export default function DocumentationCards(props: IProps) {
  return (
    <a 
      href={props.href}
      style={{ textDecoration: 'none', color: 'inherit' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box>
        <div style={{textAlign: 'center'}}>
          {props.icon}
          <div >{props.title}</div>
        </div>
      </Box>
    </a>
  )
}