import { Check, CloseOutlined } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Card = styled.div`
{
  width: inherit;
  margin-bottom: 20px;
  border: solid #6F6969 1pt;
  height: 70px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  transition-property: box-shadow;
  transition-duration: .1s;
  &:hover {
    cursor:pointer;
    box-shadow: 0em 0em .3em .1em gray;
  }
}
`;

interface IProps2 {
  cardTitle: string;
  cardIcon: JSX.Element;
  completed: number;
  onClick: () => void;
  guideSteps: number;
}

/**
 * Displays the guide cards. Shows completion percentage, and link to the appropriate guide
 * @param props 
 * @returns 
 */
export default function GuideCard(props:IProps2) {

  /**
   * Determines the percentage of completion, based on input props. Returns 0 if negative (can be negative since on the initial load of the helpcenter, we set the completion to -1 for each step to avoid opening the wrong step)
   * @returns 
   */
  function completionPercentage() {
    const result = Math.ceil(props.completed / (props.guideSteps -1) * 100);
    if (result <= 0) {
      return 0;
    }
    else {
      return result;
    }
  }

  /**
   * Logic to determine what to display. Options: Completed, Not completed, or a percentage + progress bar
   * @returns 
   */
  function displayIcon () {
    if (props.completed >= props.guideSteps - 1) {
      return (
        <><Check />Completed</>
      )
    }
    else if(!props.completed) {
      return (
      <><CloseOutlined />Not completed</>
      )
    }
    else {
      return (
        <>
        <Box style={{width: '30px', marginRight: '5px'}}>
          <CircularProgressbar
            value={completionPercentage()}
            styles={buildStyles({
              pathColor: 'secondary',
            })}
          />
        </Box>
        {completionPercentage()}% complete
        </>
      )
    }
  }

  return(
    <Card onClick={() => props.onClick()}>
      <Box
        style={{width:'35%', height:'inherit', background: '#1B2430', borderRight: 'solid #6F6969 1pt', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        {props.cardIcon}
      </Box>
      <Box 
        style={{flex: '1',  display: 'flex', flexDirection: 'column', margin: '2px 2px 2px 5px' }}
      >
        <Box 
          style={{flexGrow: '1', display: 'flex', alignItems: 'center'}}
        >
          {props.cardTitle}
        </Box>
        <Box
          style={{color: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center'}}
        >
          {displayIcon()}
        </Box>
      </Box>
    </Card>
  )
}