import { Typography as MUIT, Box as MUIBox} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Box = styled(MUIBox)`
  margin: 10px 0;
`;

const Typography = styled(MUIT)`
  margin: 5px 0;
`;

export const ReplicationWorkflowGuide: {title: string, children: JSX.Element}[] = [
  {
    title: 'What is a replication workflow?',
    children:
    <Box>
      <Typography>A replication workflow is ut euismod a enim vitae ultrices. Maecenas sodales urna id sem lacinia, et luctus libero consequat. Ut ipsum odio, eleifend a ultricies sit amet, aliquam non erat. Pellentesque elementum a nisi et mattis. Sed faucibus purus nec ante consequat pulvinar. Nam porta mauris magna, quis imperdiet est fringilla et.</Typography>
    </Box>
  },
  {
    title: 'Define data sources',
    children:
      <>
        <Typography>Navigate to the 'Data Sources' page by clicking the button on the navigation sidebar.</Typography>
        <Box>
          <img src={require('../icons/dataSourcesRoute.png')} alt="Data sources route icon" />
        </Box>
        <Typography>Click on the 'Add Data Source' button. This will bring up a new dialog with input choices.<i>Note: you must know the size of your database to proceed.</i></Typography>
        <Box>
          <img src={require('../icons/addDataSource.png')} alt="Add data source icon" />
        </Box>
        <Typography>For replication workflows, you will need at least 1 source, 1 target (this could be either a self-hosted target, or you may use Redactics Target if you wish Redactics to host the database), and possibly a transformation source, if you are planning on performing any redactions.</Typography>
      </>
  },
  {
    title: 'Set up agent',
    children:
    <>
      <Typography>Navigate to the 'Agents' page.</Typography>
      <Box>
        <img src={require('../icons/agentMenu.png')} alt="Agent route icon" />
      </Box>
        <Typography>Click on the 'Install New Agent' button. This will bring up a dialog with a few options. Here, you map your previously defined data sources to your agent. </Typography>
        <Typography><i>Note: whenever you make changes to the data sources, you must re-install the agent.</i></Typography>
        <Typography><i>Note: whenever you toggle Google DLP, you must re-install your agent.</i></Typography>
      <Box mt={4}>
        <img src={require('../icons/installNewAgent.png')} alt="Agent route icon" />
      </Box>
    </>
  },
  {
    title: 'Install agent',
    children:
    <Box>
      <Typography>Create a new file '~/redactics/values.yaml'. This file will be used to install the agent with your data sources. Copy and paste the yaml code from the 'Agent Config File' section into the newly created file. There are a few lines denoted with changeme: you must update these lines accordingly.</Typography>
      <Typography><i>Instructions on how to install the cloud hosted agent here</i></Typography>
      <Typography>Under 'Agent Helm Upgrade/Install Command', copy. paste and run the command. If there are no errors, you should eventually see a green check mark beside your agent.</Typography>
    </Box>
  },
  {
    title: 'Create a workflow',
    children: 
    <Box>
      <Typography>Navigate to the Workflows/Configurations page.</Typography>
      <Box>
        <img src={require('../icons/workflowConfigurationsMenu.png')} alt="Configurations menu icon"/>
      </Box>
      <Typography>Click on the 'Define New Workflow' button to create a new workflow, and select the 'replication' option.</Typography>
      <Box>
        <img src={require('../icons/addNewWorkflowIcon.png')} alt="Add new workflow icon"/>
      </Box>
    </Box>
  },
  {
    title: 'Replication workflow set up',
    children: 
    <Box>
      <Typography>For replication workflows, the general idea is to select 1 input source, perform redactions, schedule snapshots, configure google DLP.</Typography>
      <Typography><i>Note on Google DLP: Users should try and manually set rules that redact PII in their data sources. Google DLP is somewhat of a safety net. It will ensure that no other fields contain PII, and will also address schema changes after the workflow has been created.</i></Typography>
    </Box>
  },
  {
    title: 'Running replication workflows',
    children:
    <Box>
      <Typography><i>Steps on how to run the replication workflow will go here.</i></Typography>
    </Box>
  },
  {
    title: 'Next steps',
    children:
    <Box>
        <Typography>Congratulations, you have set up your first Replication workflow. You may come back and view this guide whenever you may need a quick refresh on replication workflows.</Typography>
        <Typography><b>Next steps?</b> Learn about ERL workflows by clicking the card below.</Typography>
    </Box>
  }
]