import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { dashboard as dashboardRoutes } from './index';

import async from '../components/Async';

import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import ProtectedRoute from './ProtectedRoute';
import UnprotectedRoute from './UnprotectedRoute';

const Login = async(() => import('../pages/auth/Login'));
const SignUp = async(() => import('../pages/auth/SignUp'));
const SignUpValidate = async(() => import('../pages/auth/SignUpValidate'));
const ResetPassword = async(() => import('../pages/auth/ResetPassword'));
const ResetPasswordEmail = async(() => import('../pages/auth/ResetPasswordEmail'));
const Privacy = async(() => import('../pages/auth/Privacy'));
const Terms = async(() => import('../pages/auth/Terms'));
const Page404 = async(() => import('../pages/auth/Page404'));
// const Page500 = async(() => import('../pages/auth/Page500'));

/* eslint-disable max-len */

const childRoutes = (Layout, routes) => routes.map(({ children, path, component: Component }, index) => (children ? (
  // Route item with children
  children.map(({ path, component: Component }, index) => (
        <ProtectedRoute exact={true} key={index} path={path} layout={Layout} component={Component} />
  ))
) : (
      // Route item without children
      <ProtectedRoute exact={true} key={index} path={path} layout={Layout} component={Component} />
)));

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardRoutes)}
      <UnprotectedRoute
        path="/login"
        exact
        component={Login}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/login/:alert"
        component={Login}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/resetpassword"
        exact
        component={ResetPasswordEmail}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/resetpassword/:token"
        component={ResetPassword}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/signup"
        exact
        component={SignUp}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/invite/:token"
        exact
        component={SignUp}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/signup/complete/:token"
        component={SignUpValidate}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/privacy"
        component={Privacy}
        layout={AuthLayout}
      />
      <UnprotectedRoute
        path="/terms"
        component={Terms}
        layout={AuthLayout}
      />
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
      <Redirect to="/login" />
    </Switch>
  </Router>
);

export default Routes;
