import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from '@material-ui/core';

import { Menu as MenuIcon, SchoolOutlined } from '@material-ui/icons';

import {
  User,
} from 'react-feather';

import Notifications from '../pages/rightSidebar/notifications/NotificationIcon';
import { ToggleButton as MUIToggle } from '@material-ui/lab';

/* eslint-disable no-undef */

const API_URL = process.env.REACT_APP_API_URL || '';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ToggleButton = styled(MUIToggle)`
  border: none;
  border-radius: 1000000000px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 10ms;
`;

// function LanguageMenu() {
//   const [anchorMenu, setAnchorMenu] = useState(null);

//   const toggleMenu = event => {
//     setAnchorMenu(event.currentTarget);
//   };

//   const closeMenu = () => {
//     setAnchorMenu(null);
//   };

//   return (
//     <React.Fragment>
//       <IconButton
//         aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
//         aria-haspopup="true"
//         onClick={toggleMenu}
//         color="inherit"
//       >
//         <Flag src="/static/img/flags/us.png" alt="English" />
//       </IconButton>
//       <Menu
//         id="menu-appbar"
//         anchorEl={anchorMenu}
//         open={Boolean(anchorMenu)}
//         onClose={closeMenu}
//       >
//         <MenuItem onClick={closeMenu}>
//           English
//         </MenuItem>
//         <MenuItem onClick={closeMenu}>
//           French
//         </MenuItem>
//         <MenuItem onClick={closeMenu}>
//           German
//         </MenuItem>
//         <MenuItem onClick={closeMenu}>
//           Dutch
//         </MenuItem>
//       </Menu>
//     </React.Fragment>
//   )
// }

/* eslint-disable react/prop-types */

function UserMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    console.log(anchorMenu);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const logout = async () => {
    try {
      const response = await fetch(`${API_URL}/users/logout`, {
        credentials: 'include',
      });

      if (response.ok) {
        closeMenu();
        window.location.href = '/login';
      }
    } catch (err) {
      console.log('CATCH ERR', error);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorMenu ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <User />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={logout}>
          Log out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const Header = ({ onDrawerToggle, toggleRightSidebar, rightSidebarOpen, rightSidebarPage }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0} style={{zIndex: 9}}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>
            <ToggleButton
              color='inherit'
              onClick={() => toggleRightSidebar('helpCenter')}
              selected={rightSidebarPage === 'helpCenter' && rightSidebarOpen}
              value='check'
            >
              <SchoolOutlined />
            </ToggleButton>
            <Notifications
              toggleRightSidebar={toggleRightSidebar}
              rightSidebarPage={rightSidebarPage}
              rightSidebarOpen={rightSidebarOpen}
            />
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default connect()(withTheme(Header));
