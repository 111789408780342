import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { spacing } from '@material-ui/system';
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
} from '@material-ui/core';

import { isWidthUp } from '@material-ui/core/withWidth';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import RightSidebar from '../pages/rightSidebar/RightSidebar';

const drawerWidth = 260;

/* eslint-disable react/prop-types */

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  // Session storage as string, need to convert to a boolean value.
  const [rightSidebarOpen, setRightSidebar] = useState(sessionStorage.getItem('rightSidebarOpen') === 'true' ?? false);

  const [page, setPage] = useState(sessionStorage.getItem('rightSidebarPage') ?? '');

  const [stackTraceViewer, setStackTraceViewer] = useState(false);

  /**
   * Saves the state variable into session storage so that the help center will remain open/closed on page reloads.
   */
  function toggleRightSidebar(newPage) {
    // If the sidebar is not open, open it and set the page
    if (!rightSidebarOpen) {
      setPage(newPage);
      sessionStorage.setItem('rightSidebarOpen', !rightSidebarOpen);
      setRightSidebar(!rightSidebarOpen);
      sessionStorage.setItem('rightSidebarPage', newPage)
    }
    // Close the sidebar by clicking the same toggle button
    else if (rightSidebarOpen && newPage === page) {
      sessionStorage.setItem('rightSidebarOpen', !rightSidebarOpen);
      setRightSidebar(!rightSidebarOpen);
      sessionStorage.removeItem('rightSidebarPage');
      if (newPage === 'notifications' && stackTraceViewer) {
        setStackTraceViewer(false);
      }
    }
    // Switch between the pages
    else {
      setPage(newPage);
      sessionStorage.setItem('rightSidebarOpen', newPage);
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function toggleStackTraceViewer() {
    setStackTraceViewer(!stackTraceViewer);
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer>
      <AppContent style={{height: '100vh', overflow: 'auto'}}>
        <Header 
          onDrawerToggle={handleDrawerToggle} 
          toggleRightSidebar={toggleRightSidebar}
          rightSidebarOpen={rightSidebarOpen}
          rightSidebarPage={page}
        />
        <MainContent p={isWidthUp('lg', width) ? 10 : 5}>
          {children}
        </MainContent>
      </AppContent>
      <RightSidebar 
        sidebarOpen={rightSidebarOpen}
        toggleRightSidebar={toggleRightSidebar}
        sidebarPage={page}
        stackTraceViewerOpen={stackTraceViewer}
        toggleStackTraceViewer={toggleStackTraceViewer}
      />
    </Root>
  );
};

export default withWidth()(Dashboard);
