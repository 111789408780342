import { Box, Typography } from "@material-ui/core";
import React from "react";

interface IProps {
  title: string;
  estimatedTime: string;
}

/**
 * Creates title for the guide pages.
 * @param props 
 * @returns 
 */
export default function HelpCenterTitle(props: IProps) {
  return (
    <Box mb={4} mt={4}>
      <Typography variant="h4">{props.title}</Typography>
      <Typography style={{color: 'grey'}}>Estimated time: {props.estimatedTime}</Typography>
    </Box>
  )
}