/* eslint-disable no-undef */

const firebaseConfig = process.env.NODE_ENV === 'production' ? {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'redactics-prod-bd3bd.firebaseapp.com',
  databaseURL: 'https://redactics-prod-bd3bd-default-rtdb.firebaseio.com',
  projectId: 'redactics-prod-bd3bd',
  appId: '1:675201805351:web:030c2deb741cbb9020b202',
  storageBucket: 'redactics-prod-bd3bd.appspot.com',
  messagingSenderId: '675201805351',
} : {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'redactics-non-prod.firebaseapp.com',
  databaseURL: 'https://redactics-non-prod.firebaseio.com',
  projectId: 'redactics-non-prod',
  appId: '1:863994757669:web:ea5d89aef57f105d4cf5cc',
  storageBucket: 'redactics-non-prod.appspot.com',
  messagingSenderId: '863994757669',
};

export default firebaseConfig;
