import { Box, Card as MuiCard, CardActions, CardContent, Typography, Button, withStyles, CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AgentFirebaseRecord } from "../../../types/redactics";
import Moment from "react-moment";
import RedacticsContext from "../../../contexts/RedacticsContext";

const styles = {
  bold: {
    'font-weight': 'bold',
  },
  notClickable: {
    'padding': '10px',
  },
  clickable: {
    'padding': '10px',
    '&:hover': {
      'cursor': 'pointer',
      'background-color': 'rgba(0, 0, 0, 0.04)',
    },
    'transition': 'background-color .2s ease-out',
  }
};

const Card = styled(MuiCard)`
  background-color: inherit;
  box-shadow: none;
  border-bottom: solid rgba(0, 0, 0, 0.3) 1pt;
  border-radius: 0;
  margin-top: 0;
`;

interface IProps {
  firebaseData: AgentFirebaseRecord;
  toggleStackTraceViewer: () => void;
  firebaseStackTraceObj: AgentFirebaseRecord;
  setFirebaseStackTraceObj: React.Dispatch<React.SetStateAction<AgentFirebaseRecord | undefined>>
  stackTraceViewerOpen: boolean;
  processingRequest?: boolean;
  classes?: any;
}

function NotificationCard(props: IProps) {

  const context: any = useContext(RedacticsContext);

  const [clicked, setClicked] = useState(false);

  function trimTitle(title: string) {
    const stringLength = title.length;
    if (stringLength < 100) {
      return title;
    }
    else {
      return title.slice(0, 100) + '...';
    }
  }

  function openStackTraceViewer() {
    // Don't open the stack trace viewer if it is a notification about first heartbeat
    if (props.firebaseData.firstHeartbeat) {
      return null;
    }
    // Isn't currently open, open, set the data
    else if (!props.stackTraceViewerOpen) {
      props.setFirebaseStackTraceObj(props.firebaseData);
      props.toggleStackTraceViewer();
    }
    // Viewer is open, user clicks on a different notification
    else if (props.stackTraceViewerOpen && props.firebaseStackTraceObj.key !== props.firebaseData.key) {
      props.setFirebaseStackTraceObj(props.firebaseData);
    }
    // User clicks on same notification, closes the window
    else if (props.stackTraceViewerOpen && props.firebaseStackTraceObj.key === props.firebaseData.key) {
      props.toggleStackTraceViewer();
    }
  }

  function firstHeartbeat() {
    if (props.firebaseData.firstHeartbeat) {
      return <>The Redactics Agent <b>{props.firebaseData.agentName}</b> has successfully reported to Redactics</>
    }
    else {
      return trimTitle(props.firebaseData.exception ?? 'Error: no exception');
    }
  }

  async function acknowledge() {
    if (props.firebaseData.ack) {
      return null;
    }
    try {
      await fetch(`${context.apiUrl}/workflow/${props.firebaseData.workflowId}/ackException`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          exceptionId: props.firebaseData.key,
        }),
      });
    } catch (err) {
      // console.log('ERR', err);
    }
  }

  function displayReadButtonState() {
    if (clicked || props.processingRequest) {
      return (
        <CircularProgress disableShrink style={{marginRight: '20px'}}/>
      );
    }
    else {
      return (
        <Button
          onClick={() => {
            acknowledge();
            setClicked(true);
          }}
          variant="outlined"
          size="small"
        >
          Mark as read
        </Button>
      );
    }
  }

  return (
    <Card>
      <CardContent onClick={() => openStackTraceViewer()} className={props.firebaseData.firstHeartbeat ? props.classes.notClickable : props.classes.clickable}>
        <Box>
          <Typography className={props.firebaseData.ack ? '' : props.classes.bold}>{firstHeartbeat()}</Typography>
          <Typography style={{ color: 'grey' }} className={props.firebaseData.ack ? '' : props.classes.bold}><i>Date:</i> <Moment fromNow>{props.firebaseData.timestamp}</Moment></Typography>
        </Box>
      </CardContent>
      {!props.firebaseData.ack &&
        <CardActions style={{ flexDirection: 'row-reverse', marginBottom: '10px' }}
        >
          {displayReadButtonState()}
        </CardActions>}
    </Card>
  )
}

export default withStyles(styles)(NotificationCard)