import { Typography as MUIT, Box as MUIBox} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Box = styled(MUIBox)`
  margin: 10px 0;
`;

const Typography = styled(MUIT)`
  margin: 5px 0;
`;

export const ErlWorkflowGuide: {title: string, children: JSX.Element}[] = [
  {
    title: 'What is an ERL workflow?',
    children:
    <Box>
      <Typography>An ERL workflow is ut euismod a enim vitae ultrices. Maecenas sodales urna id sem lacinia, et luctus libero consequat. Ut ipsum odio, eleifend a ultricies sit amet, aliquam non erat. Pellentesque elementum a nisi et mattis. Sed faucibus purus nec ante consequat pulvinar. Nam porta mauris magna, quis imperdiet est fringilla et.</Typography>
    </Box>
  },
  {
    title: 'Define data sources',
    children:
      <>
        <Typography>Navigate to the 'Data Sources' page by clicking the button on the navigation sidebar.</Typography>
        <Box>
          <img src={require('../icons/dataSourcesRoute.png')} alt="Data sources route icon" />
        </Box>
        <Typography>Click on the 'Add Data Source' button. This will bring up a new dialog with input choices.<i>Note: you must know the size of your database to proceed.</i></Typography>
        <Box>
          <img src={require('../icons/addDataSource.png')} alt="Add data source icon" />
        </Box>
        <Typography>For ERL workflows, use the input function 'Source'.</Typography>
      </>
  },
  {
    title: 'Set up agent',
    children:
    <>
      <Typography>Navigate to the 'Agents' page.</Typography>
      <Box>
        <img src={require('../icons/agentMenu.png')} alt="Agent route icon" />
      </Box>
      <Typography>Click on the 'Install New Agent' button. This will bring up a dialog with a few options. Here, you map your previously defined data sources to your agent. </Typography><Typography><i>Note: whenever you make changes to the data sources, you must re-install the agent.</i></Typography>
      <Box mt={4}>
        <img src={require('../icons/installNewAgent.png')} alt="Agent route icon" />
      </Box>
    </>
  },
  {
    title: 'Install agent',
    children:
    <Box>
      <Typography>Create a new file '~/redactics/values.yaml'. This file will be used to install the agent with your data sources. Copy and paste the yaml code from the 'Agent Config File' section into the newly created file. There are a few lines denoted with changeme: you must update these lines accordingly.</Typography>
      <Typography><i>Instructions on how to install the cloud hosted agent here</i></Typography>
      <Typography>Under 'Agent Helm Upgrade/Install Command', copy. paste and run the command. If there are no errors, you should eventually see a green check mark beside your agent.</Typography>
    </Box>
  },
  {
    title: 'Create a workflow',
    children: 
    <Box>
      <Typography>Navigate to the Workflows/Configurations page.</Typography>
      <Box>
        <img src={require('../icons/workflowConfigurationsMenu.png')} alt="Configurations menu icon"/>
      </Box>
      <Typography>Click on the 'Define New Workflow' button to create a new workflow, and select the 'ERL' option.</Typography>
      <Box>
        <img src={require('../icons/addNewWorkflowIcon.png')} alt="Add new workflow icon"/>
      </Box>
    </Box>
  },
  {
    title: 'ERL workflow set up',
    children: 
    <Box>
      <Typography>For ERL workflows, the general idea is to select 1 or more input sources, perform redactions, and then set up a destination for the new data. You can automate this job on a schedule, and set table constraints.</Typography>
    </Box>
  },
  {
    title: 'Run ERL workflow',
    children:
    <Box>
      <Typography><i>Steps on how to run the ERL workflow will go here.</i></Typography>
    </Box>
  },
  {
    title: 'Next steps',
    children:
    <Box>
        <Typography>Congratulations, you have set up your first ERL workflow. You may come back and view this guide whenever you may need a quick refresh on ERL workflows.</Typography>
        <Typography><b>Next steps?</b> Learn about Replication workflows by clicking the card below.</Typography>
    </Box>
  }
]