import { Box, Button, CircularProgress, IconButton, Typography, withStyles } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import NotificationCard from "./NotificationCard";
import StacktraceViewer from "./StackTraceViewer";
import { query, ref, orderByChild, onValue, getDatabase } from "firebase/database";
import { AgentFirebaseRecord } from "../../../types/redactics";
import { initializeApp } from 'firebase/app';
import firebaseConfig from "../../../firebaseConfig";
import RedacticsContext from "../../../contexts/RedacticsContext";

const app = initializeApp(firebaseConfig);
const fbDatabase = getDatabase(app);

const styles = {
  borderBox: {
    'margin': '0 10px',
    'width': '25%',
  },
  selectedButton: {
    'background-color': '#2196f3',
    'color': 'white',
    '&:hover': {
      'background-color': 'rgb(23, 105, 170)',
    },
  },
}

interface IProps {
  toggleRightSidebar: (newPage: string) => void;
  stackTraceViewerOpen: boolean;
  toggleStackTraceViewer: () => void;
  classes?: any;
}

function NewNotifications(props: IProps) {

  const context: any = useContext(RedacticsContext);
  const [firebaseData, setFirebaseData] = useState<AgentFirebaseRecord[]>();
  const [firebaseStackTraceObj, setFirebaseStackTraceObj] = useState<AgentFirebaseRecord>();
  const [notificationColumn, setNotificationColumn] = useState<'unread' | 'all'>('unread');
  const [acknowledgeAll, setAcknowledgeAll] = useState(false);

  useEffect(() => {
    function processFirebaseData() {
      let exceptionsFound = 0;
      let displayExceptions = 10; // display this many exceptions
      const notifications = query(ref(fbDatabase, `notifications/${context.companyId}`), orderByChild('timestamp'));
      onValue(notifications, (snapshot) => {
        const data = snapshot.val();
        if (!data) { return; }
        const formattedData = [];
        exceptionsFound = 0;
  
        // show displayExceptions most recent exceptions
        for (const [key, v] of Object.entries(data).reverse()) {
          // cast Firebase data to AgentFirebaseRecord type
          const val: AgentFirebaseRecord = v as AgentFirebaseRecord;
  
          //console.log("VAL", val)
          if (!val.heartbeat && exceptionsFound < displayExceptions) {
            // attach key to data
            val.key = key;
            exceptionsFound++;
            formattedData.push(val);
          }
        }

        // When no more unread messages, turn off the circular spinner flag
        let ackAll = true;
        formattedData.forEach(notification => {
          if (!notification.ack) {
            ackAll = false;
          }
        });
        if (ackAll) {
          setAcknowledgeAll(false);
        }
        // console.log(formattedData)
        setFirebaseData(formattedData);
      });
    }

    processFirebaseData();
  }, [context.companyId]);

  

  async function ackAll() {
    try {
      setAcknowledgeAll(true);
      fetch(`${context.apiUrl}/workflow/ackAll`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
    } catch (err) {
      console.log('ERR', err);
    }
  }

  function displayFirebaseData(unreadOnly: 'unread' | 'all', acknowledgeAll: boolean) {
    if (!firebaseData) {
      return (
        <Box style={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Box margin={10}>
            <Typography>Loading notifications</Typography>
          </Box>
          <CircularProgress style={{ width: '60px', height: '60px' }} />
        </Box>)
    }
    else {
      let firebaseCopy = firebaseData;
      // filter out results that haven't been acknowledged
      if (unreadOnly === 'unread') {
        firebaseCopy = firebaseCopy.filter(row => !row.ack);
      }
      if (!firebaseCopy.length) {
        return <Box mt={10} style={{ textAlign: 'center' }}>No notifications</Box>
      }

      return (
        <>
          <Box
            style={{ overflow: 'auto', height: `calc(100vh - ${height}px` }}
          >
            {firebaseCopy.map(row =>
              <NotificationCard
                key={row.key}
                firebaseData={row}
                toggleStackTraceViewer={props.toggleStackTraceViewer}
                firebaseStackTraceObj={firebaseStackTraceObj!}
                setFirebaseStackTraceObj={setFirebaseStackTraceObj}
                stackTraceViewerOpen={props.stackTraceViewerOpen}
                processingRequest={acknowledgeAll}
              />)}
            <Box display={unreadOnly === 'unread' ? 'block' : 'none'} id="mark-as-read" style={{ height: '58px', position: 'sticky', bottom: 0, textAlign: 'right' }}>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: '10px' }}
                onClick={() => ackAll()}
              >
                Mark all as read
              </Button>
            </Box>
          </Box>
        </>
      )
    }
  }

  // Used to calculate the height of the scroll section
  const height = document.getElementById('notification-display')?.offsetHeight ?? 0;

  return (
    <>
      <Box id="notification-display">
        <Box style={{ display: 'flex', alignItems: 'center', margin: '0 15px' }}>
          <h3 style={{ fontWeight: '600', fontSize: '22px' }}>Notifications</h3>
          <IconButton
            onClick={() => props.toggleRightSidebar('notifications')}
            style={{ marginLeft: 'auto' }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>

        <Box style={{ textAlign: 'center', paddingBottom: '10px', borderBottom: 'solid rgba(0, 0, 0, 0.3) 1pt' }}>
          <Button
            className={`${notificationColumn === 'unread' ? props.classes.selectedButton : ''} ${props.classes.borderBox}`}
            variant="outlined"
            onClick={() => setNotificationColumn('unread')}
          >
            Unread
          </Button>
          <Button
            variant="outlined"
            className={`${notificationColumn === 'all' ? props.classes.selectedButton : ''} ${props.classes.borderBox}`}
            onClick={() => setNotificationColumn('all')}
          >
            All
          </Button>
        </Box>
      </Box>

      {displayFirebaseData(notificationColumn, acknowledgeAll)}

      {/* Ensure firebaseObj is loaded before loading this component */}
      {firebaseStackTraceObj &&
        <StacktraceViewer
          open={props.stackTraceViewerOpen}
          firebaseObject={firebaseStackTraceObj}
          toggleStackTraceViewer={props.toggleStackTraceViewer}
        />}
    </>
  )
}

export default withStyles(styles)(NewNotifications);