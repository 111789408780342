import React from "react";
import styled from "styled-components";
import HelpCenter from "./Main";
import NewNotifications from "./notifications/Notifications";

const Drawer = styled.div`
  {
    width: 300px;
    margin-right: -300px;
    flex-shrink: 0;
    background: #E0E0E0;
    border-left: solid lightgray 1pt;
    transition-property: margin-right;
    transition-duration: .5s;
    overflow: auto;
    height: 100vh;
    z-index: 10;
  }
`;

interface IProps {
  sidebarOpen: boolean;
  toggleRightSidebar: (newPage: string) => void;
  sidebarPage: string;
  stackTraceViewerOpen: boolean;
  toggleStackTraceViewer: () => void;
}

export default function RightSidebar(props: IProps) {

  function sidebarRouter(page: string) {
    switch (page) {
      case 'notifications':
        return (
          <NewNotifications
            toggleRightSidebar={props.toggleRightSidebar}
            stackTraceViewerOpen={props.stackTraceViewerOpen}
            toggleStackTraceViewer={props.toggleStackTraceViewer}
          />
        );
      case 'helpCenter':
        return (
          <HelpCenter
            toggleHelpCenter={props.toggleRightSidebar}
            helpCenterOpen={props.sidebarOpen}
          />
        );
    }
  }

  return (
    <Drawer style={props.sidebarOpen ? {marginRight: '0px'} : {} }>
      {sidebarRouter(props.sidebarPage)}
    </Drawer>
  )
}